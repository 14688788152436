.shit-picker .events {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.shit-picker .events .event {
  flex: 100%;
}

.shit-picker .events .event .inner {
  padding: 5px;
}

.shit-picker-event {
  background-color: #fff;
  font-family: monospace;
  color: #555;
  border-radius: 5px;
}

.shit-picker-event .inline {
  flex-direction: row;
  display: flex;
}

.shit-picker-event .icon {
  width: 50%;
}

.shit-picker-event .count {
  width: 20%;
}

.shit-picker-event .opponent-count {
  width: 20%;
}

.shit-picker-event .add-button {
  height: 100%;
  width: 10%;
}

.player {
  color: #333;
  font-family: monospace; 
}

.player .name {
}

.player .score {
  margin: 10px;
  display: flex;
  flex-direction: row;
}

.player .score > div {
  width: 25%;
}

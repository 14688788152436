.scoreboard {
  color: #555;
}

.total-score {
  text-align: center;
  font-size: 100px;
}

.scoreboard .overview {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.scoreboard .overview .player-overview {
  width: 50%;
  padding: 10px;
}

.scoreboard .overview .player-overview .inner {

}

.leftScoreProgressBar {
  transform: rotate(180deg);
}

.scoreboard .cashout .button-group {
  display: flex;
}

.scoreboard .cashout .button-group .each {
  padding: 10px;
  width: 50%;
}

.match {
  font-size: 15px;
  background-color: #dcc9af ;
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.match .Col {
  width: 100%;
}

.match .Col .score {
  display: flex;
  align-items: center;
  justify-content: center;
}

.match .Col .score .score-item {
  width: 100%;
}

.match .match-detail {

}
.match .match-detail .each {
  border-width: 1px;
  border-color: black;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: white;
  width: 100%;
}

.match .match-detail .each .kill-extra {
  font-size: 30px;
}
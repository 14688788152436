.Loading {
  width: 200px;
  height: 200px;
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-left: auto;
  background-color: #333333;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  opacity: 50%;
}

.Loading .Content {
  width: 90%;
  height: 90%;
  border-radius: 20px;
  background-color: #333333;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.Loading .Content .Icon {
  width: 100px;
  height: 100px;
}

.Loading .Content .Icon .Spinner {
  width: 100%;
  height: 100%;
  border: 5px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.Loading .Content .Description {
  color: #CCCCCC;
  margin-top: 20px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.todo {
  width: 100%;
}

.todo .input-group {
  flex-direction: column;
  border-radius: 5px;
  background-color: #fff;
}

.todo .input-group .input-row {
  border-bottom: 1px solid #ccc;
}

.todo .inline {
  flex-direction: row;
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.todo .input-group .input-row input {
  padding: 5px 15px;
  display: inline-block;
  border: none;
  box-sizing: border-box;
  width: 100%;
}

.todo .input-group .last {
  border-bottom: none;
}

.todo .button-group {
  padding: 10px 0px;
}

/* ITEM */
.todo .item {
  width: 100%;
  padding: 2px;
}

.todo .item .description {
  width: 100%;
}

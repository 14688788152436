.debt {
  color: #333333;
  padding: 10px;
}

.debt .debts {
  background-color: white;
  border-radius: 20px;
  margin: 5px 0px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: right;
  padding-right: 20px;
}

.debt .info .title {
  padding-left: 20px;
  text-align: left;
  width: 50%;
}

.debt .info .content {
  width: 50%;
}

.debt .total-repayment {
  background-color: white;
  border-radius: 20px;
  margin: 5px 0px;
  padding: 5px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: right;
  padding-right: 20px;
}

.debt .history {
  overflow: scroll;
  height: 50%;
}

.debt .history .item {
  background-color: white;
  border-radius: 20px;
  margin: 5px 0px;
  padding: 5px;

  display: flex;
  flex-direction: row;
}

.debt .history .debt {
  background-color: #FF8866;
}

.debt .history .interest {
  background-color: #FF8866;
}

.debt .history .repayment {
  background-color: #B3EAA2;
}

.debt .history .item .left {
  width: 70%;
  display: block;
  justify-content: left;
  align-items: left;
}

.debt .history .item .left .note {
  font-size: 60%;
}

.debt .history .item .right {
  float: right;
  width: 30%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
}

.debt .history .item .right .amount {
  font-family: monospace;
  font-size: 15px;
}


.debt .input-group {
  flex-direction: column;
  border-radius: 5px;
  background-color: #fff;
}

.debt .input-group .input-row {
  border-bottom: 1px solid #ccc;
}

.debt .inline {
  flex-direction: row;
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.debt .input-group .input-row input {
  padding: 5px 15px;
  display: inline-block;
  border: none;
  box-sizing: border-box;
  width: 100%;
}

.debt .input-group .last {
  border-bottom: none;
}

.debt .button-group {
  padding: 10px 0px;
}


.theme {
  text-align: center;
  background-color: #FEF5E7;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

p {
  font-size: calc(10px + 1vmin);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: white;
}

.menu-bar {
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}

.layout {
  width: 100%;
  padding: 10px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #E6B0AA;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Add a background color to the submit button on mouse-over */
button:hover {
  background-color: #AC8A70;
}

Menu {
  width: 100%;
  background-color: #AC9270;
  color: white;
  padding: 10px;
  margin: 5px 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.dashboard {
  width: 100%;
}

.row {
  padding-bottom: 5px;
}

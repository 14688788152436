.anniversaries {
}

.annivesaries-header {
  font-family: monospace;
  color: #3A3A3A;
  font-size: 25px;
}

.anniversary {
  font-family: monospace; 
  background-color: white;
  color: #3a3a3a;
  border-radius: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.juiz-choices {
  display: flex;
  flex-wrap: wrap;
}

.juiz-choices-choice {
  width: 50%;
}

.juiz-choices-choice-border {
  margin: 5px;
}

.juiz-choices-choice-content {
  height: calc(40px + 1vmin);
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.juiz-choices-choice-content:hover {
  background-color: red;
}

.juiz-choices-choice-content:active {
  background-color: pink;
}

.task {
  padding-bottom: 5px;
}

.task .inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  color: #555;
  border-radius: 5px;
}

.task .description {
  width: 90%; 
}

.task .done-button {
  width: 10%;
}

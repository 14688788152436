.workout {
  font-size: 15px;
  background-color: #dcc9af ;
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 2px;
  color: white;
}

.workout .Row .Col {
  margin-left: 1px;
  margin-right: 1px;
}

.exercise {
  height: 100%;
}

.exercise-lives {
  font-family: Copperplate;
}

.exercise-lives .amount {
  font-size: 500%;
}

.exercise-lives .icon {
  font-size: 200%;
}

.claim-button {
   height: 250px;
   width: 250px;
   border-radius: 100%;
   color: white;
   padding: 20px;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   font-size: 40px;
   font-family: cursive;
}

.exercise-bottom {
  color: black;
  position: absolute;
  bottom: 0;
  margin-bottom: 100px;
  width: 100%;
  background-color: #282c34;
}

.exercise-bottom-display {
  color: white;
}